import { Button as DsButton } from 'src/design-system'
import React, { ComponentPropsWithoutRef, FC } from 'react'
import { WithAnalytics } from 'components/with-analytics'

type BaseButtonProps = {
  event?: string
}

type ButtonProps = ComponentPropsWithoutRef<'button'> &
  ComponentPropsWithoutRef<'a'> &
  BaseButtonProps

export const Button: FC<ButtonProps> = ({ event, ...props }) => {
  const elementType = 'href' in props ? 'a' : 'button'

  return (
    <WithAnalytics event={event}>
      <DsButton as={elementType} {...props} />
    </WithAnalytics>
  )
}
