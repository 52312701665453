import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useStore } from 'store/context'
import { Logo } from '../logo'

export const BootstrapCurtain: React.FC = observer(() => {
  const { bootstrapping } = useStore()

  if (!bootstrapping) return null

  return (
    <div className="fixed inset-0 bg-white z-tooltip flex items-center justify-center">
      <div className="animate-fade-in animation-delay-1 flex flex-col gap-4">
        <Logo className="h-10" />
        Loading your workspace
      </div>
    </div>
  )
})
