import { Button } from 'components/atoms/button'
import { Collapsible } from '../src/components/atoms/collapsible'
import { forRails } from 'components/for-rails'
import { registerComponents } from '../src/utils/react/registry'
import { WithAnalytics } from '../src/components/with-analytics'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { BootstrapCurtain } from 'components/atoms/bootstrap-curtain'

registerComponents({
  Button: forRails(Button),
  Collapsible: forRails(Collapsible),
  WithAnalytics: forRails(WithAnalytics),
  HtmlContent: forRails(HtmlContent),
  BootstrapCurtain: BootstrapCurtain,
})
